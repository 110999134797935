<template>
  <div class="container_con">
    <el-card>
      <div class="con_title"><span></span> 采购计划</div>
      <el-form ref="form" :rules="rules" :model="form" label-width="160px">
        <div class="flex">
          <div>
            <el-form-item label="计划日期：" prop="purchase_plan_date">
              <el-date-picker
                v-model="form.purchase_plan_date"
                type="date" value-format="YYYY-MM-DD"
                placeholder="选择日期"
                style="width:300px"
              >
              </el-date-picker>
            </el-form-item>
            <el-form-item label="部门：" prop="department_id">
              <el-cascader style="width:300px" v-model="form.department_id" :options="list"
                  :props="{ expandTrigger: 'hover',checkStrictly: true,emitPath:false,value:'id',label:'title',children:'_child'}"
                  clearable></el-cascader>
            </el-form-item>
            <el-form-item label="采购员：" prop="plan_admin_id">
              <el-select v-model="form.plan_admin_id" placeholder="请选择"
                  style="width: 100%;; margin-right: 15px;" filterable>
                  <el-option :label="item.full_name+'('+item.department_name+')'" v-for="(item,index) in cglist"
                      :key="index" :value="item.id"></el-option>
              </el-select>
            </el-form-item>
          </div>
          <div>
            <el-form-item :label="!form.id?'计划编号前缀：':'单据编号：'" prop="plan_no">
                <el-select v-model="form.plan_no" v-if="!form.id" placeholder="请选择"
                    style="width: 100%;; margin-right: 15px;" filterable>
                    <el-option :label="item.value+'__'+item.title" v-for="(item,index) in zjlist"
                        :key="index" :value="item.value"></el-option>
                </el-select>
                <el-input v-if="form.id" disabled style="width: 100%;" v-model="form.plan_no"
                    placeholder="请输入单据编号" />
            </el-form-item>
            
            <el-form-item label="供货单位：" prop="merchant_id">
              <el-select v-model="form.merchant_id" placeholder="请选择"  @change="get_price()" 
                  style="width: 100%;; margin-right: 15px;" filterable ref="kehu">
                  <el-option :label="item.merchant_name+'-'+item.mnemonic_code" v-for="(item,index) in dwlist"
                      :key="index" :value="item.id"></el-option>
              </el-select>
            </el-form-item>
            
            <el-form-item label="备注：" prop="plan_remark">
                <el-input v-model="form.plan_remark" placeholder="请输入备注" style="width:100%" />
            </el-form-item>
          </div>
        </div>

        <div class="table_Box">
          <el-table :data="have_sele_arr" style="width: 100%">
            <el-table-column prop="product_no" label="产品编号" />
            <el-table-column prop="product_name" label="品名" />
            <!-- <el-table-column prop="mnemonic_code" label="助记码" /> -->
            <el-table-column prop="model" label="型号规格" />
            <el-table-column prop="manufacturer" label="生产厂家" />
            <el-table-column label="产品数量" width="150">
              <template v-slot="scope">
                <div>
                  <el-input-number
                    v-model="scope.row.product_num"
                    controls-position="right" @change="num_change(scope.$index)"
                    :min="0"
                    size="small"
                  ></el-input-number>
                </div>
              </template>
            </el-table-column>
            <el-table-column label="产品单价" width="200">
              <template v-slot="scope">
                <div>
                    <el-input-number
                      v-model="scope.row.product_price"
                      controls-position="right"
                      :min="0"
                      :step="0.001"
                      size="small" style="width:150px"
                    ></el-input-number> 元</div>
              </template>
            </el-table-column>
            <el-table-column label="金额">
              <template v-slot="scope">
                <div style="color: #FF5115;">
                  {{(scope.row.product_num*scope.row.product_price || 0).toFixed(3)}}
                </div>
              </template>
            </el-table-column>
            <el-table-column label="通知业务员" width="200">
              <template v-slot="scope">
                <div>
                  <el-select v-model="scope.row.salesman_id" placeholder="请选择"
                      style="width: 100%; margin-right: 15px;" filterable clearable>
                      <el-option :label="item.full_name+'('+item.department_name+')'" v-for="(item,index) in ywlist"
                          :key="index" :value="item.id"></el-option>
                  </el-select>
                </div>
              </template>
            </el-table-column>
            
            <el-table-column label="验收数量" width="150">
              <template v-slot="scope">
                <div>
                  <el-input-number
                    v-model="scope.row.acceptance_num"
                    controls-position="right"
                    :min="0"
                    size="small"
                  ></el-input-number>
                </div>
              </template>
            </el-table-column>
            
            <el-table-column label="产品批号" width="150">
              <template v-slot="scope">
                <div>
                  <el-input v-model="scope.row.batch_number" placeholder="请输入产品批号" style="width:100%" />
                </div>
              </template>
            </el-table-column>
            
            <el-table-column label="有效期至"  width="170">
               <template v-slot="scope">
                  <div>
                      <el-date-picker
                        v-model="scope.row.valid_until"
                        type="date" value-format="YYYY-MM-DD"
                        placeholder="选择日期(长期不选)" :shortcuts="shortcuts" 
                        style="width:100%"
                      >
                      </el-date-picker>
                  </div>
              </template>
            </el-table-column>
            
            
            <el-table-column label="验收结论" width="150">
              <template v-slot="scope">
                <div>
                  <el-select v-model="scope.row.acceptance_conclusion" placeholder="请选择"
                      style="width:100%" >
                      <el-option :label="item2.title" v-for="(item2,index2) in ysjllist"
                          :key="index2" :value="item2.title"></el-option>
                  </el-select>
                </div>
              </template>
            </el-table-column>
            <el-table-column label="质量情况" width="150">
              <template v-slot="scope">
                <div>
                  <el-select v-model="scope.row.quality_situation" placeholder="请选择"
                      style="width:100%" >
                      <el-option :label="item2.title" v-for="(item2,index2) in zlqklist"
                          :key="index2" :value="item2.title"></el-option>
                  </el-select>
                </div>
              </template>
            </el-table-column>
            
            <el-table-column label="仓库货架" width="150">
              <template v-slot="scope">
                <div>
                  <el-select v-model="scope.row.warehouse_id" placeholder="请选择货位" clearable
                      style="width:100%" filterable>
                      <el-option :label="item.cargo_space_name" v-for="(item,index) in hwlist"
                          :key="index" :value="item.id"></el-option>
                  </el-select>
                </div>
              </template>
            </el-table-column>
            
            <el-table-column fixed="right" label="操作">
              <template v-slot="scope">
                <el-button type="text" size="small" @click="handleDelete(scope.$index)">删除</el-button>
              </template>
            </el-table-column>
          </el-table>
          
          
		  <div class="table_title flex_ac" style="padding-bottom: 20px;">
		    采购产品： <span @click="sele_products">选择产品</span>
		  			<div class="t_money margin_a">合计金额: <b style="color: red; font: 16px;">{{get_all(have_sele_arr,'product_num','product_price')}}</b></div>
		  </div>
		  
        </div>
        
        
        <el-card style="margin-bottom: 15px;" >
          <div class="con_title"><span></span> 验收</div>
            <el-form-item label="验收编号前缀" prop="acceptance_no">
                <el-select v-model="form.acceptance_no" placeholder="请选择"
                    style="width: 300px; margin-right: 15px;" filterable>
                    <el-option :label="item.value+'__'+item.title" v-for="(item,index) in zjlist2"
                        :key="index" :value="item.value"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="验收日期：" prop="purchase_acceptance_date">
              <el-date-picker
                v-model="form.purchase_acceptance_date"
                type="date" value-format="YYYY-MM-DD"
                placeholder="选择日期"
                style="width:300px"
              >
              </el-date-picker>
            </el-form-item>
            <el-form-item label="验收员:" prop="acceptance_admin_id">
              <el-select v-model="form.acceptance_admin_id" placeholder="请选择"
                  style="width:300px" filterable>
                  <el-option :label="item.full_name+'('+item.department_name+')'" v-for="(item,index) in yslist"
                      :key="index" :value="item.id"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="备注：" prop="acceptance_remark">
                <el-input v-model="form.acceptance_remark" placeholder="请输入备注" style="width:300px" />
            </el-form-item>
        </el-card>
        
        
        <el-card style="margin-bottom: 15px;">
          <div class="con_title"><span></span> 入库信息</div>
            <el-form-item label="入库编号前缀" prop="storage_no">
                <el-select v-model="form.storage_no" placeholder="请选择"
                    style="width: 300px; margin-right: 15px;" filterable>
                    <el-option :label="item.value+'__'+item.title" v-for="(item,index) in zjlist3"
                        :key="index" :value="item.value"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="入库日期：" prop="purchase_storage_date">
              <el-date-picker
                v-model="form.purchase_storage_date"
                type="date" value-format="YYYY-MM-DD"
                placeholder="选择日期"
                style="width:300px"
              >
              </el-date-picker>
            </el-form-item>
            <el-form-item label="保管员:" prop="storage_admin_id">
              <el-select v-model="form.storage_admin_id" placeholder="请选择"
                  style="width:300px" filterable>
                  <el-option :label="item.full_name+'('+item.department_name+')'" v-for="(item,index) in bglist"
                      :key="index" :value="item.id"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="备注：" prop="storage_remark">
                <el-input v-model="form.storage_remark" placeholder="请输入备注" style="width:300px" />
            </el-form-item>
        </el-card>
        
        <div class="con_title"><span></span> 审批信息</div>
        
        <div class="flex_ac" v-for="(item2,index2) in sh_arr" :key="index2">
            <el-form-item :label="item2.title+'意见：'" style="width:400px">
              <el-select v-model="item2.opinion" placeholder="请选择意见" style="width:100%">
                <el-option label="同意" value="1"></el-option>
                <el-option label="不同意" value="0"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="经办人:" style="width:400px">
              <el-select v-model="item2.admin_id" placeholder="请选择"
                  style="width: width:100% " filterable>
                  <el-option :label="item.full_name+'('+item.department_name+')'" v-for="(item,index) in rylist" v-show="item['is_'+item2.key]==1"
                      :key="index" :value="item.id"></el-option>
              </el-select>
            </el-form-item>
        </div>
        
        
      </el-form>
    </el-card>
  </div>
  <div class="bottom_btn">
    <el-button type="primary" @click="submitForm('form')" :loading="is_ajax">提交</el-button>
    <el-button @click="$router.go(-1)">取消</el-button>
  </div>
  <selectProducts ref="selectProducts" @after_sele="after_sele_Products" />
</template>

<script>
export default {
  name: "addpurchasePlan",
  data() {
    return {
      id: '',
      is_ajax: false,
      form: {
          
      },
      rules: {
        purchase_plan_date: [
          { required: true, message: "计划日期不能为空",trigger: "blur"}
        ],
        plan_no: [
          { required: true, message: "计划编号前缀不能为空",trigger: "blur"}
        ],
        department_id: [
          { required: true, message: "部门不能为空",trigger: "blur"}
        ],
        plan_admin_id: [
          { required: true, message: "采购员不能为空",trigger: "blur"}
        ],
        merchant_id: [
          { required: true, message: "供货单位不能为空",trigger: "blur"}
        ],
        acceptance_no: [
          { required: true, message: "验收前缀不能为空",trigger: "blur"}
        ],
        purchase_acceptance_date: [
          { required: true, message: "验收日期不能为空",trigger: "blur"}
        ],
        acceptance_admin_id: [
          { required: true, message: "验收员不能为空",trigger: "blur"}
        ],
        storage_no: [
          { required: true, message: "入库前缀不能为空",trigger: "blur"}
        ],
        purchase_storage_date: [
          { required: true, message: "入库日期不能为空",trigger: "blur"}
        ],
        storage_admin_id: [
          { required: true, message: "保管员不能为空",trigger: "blur"}
        ]
      },
      zjlist:[],
      zjlist2:[],
      zjlist3:[],
      list:[],
      rylist:[],
      cglist:[],
      dwlist:[],
      sh_arr:[
          {admin_id:'',opinion:'1',title:'业务部',key:'salesman'},
          {admin_id:'',opinion:'1',title:'质管部',key:'quality'},
          {admin_id:'',opinion:'1',title:'销售部',key:'sale'},
          {admin_id:'',opinion:'1',title:'总经理',key:'president'}
      ],
      // 已选择产品
      have_sele_arr:[],
      hwlist:[],
      ywlist:[],
      yslist:[],
      bglist:[],
      ysjllist:[{title:'验收合格'},{title:'验收不合格'}],
      zlqklist:[{title:'质量合格'},{title:'质量不合格'}],
      shortcuts: [{
        text: '2099-12-31',
        value:'2099-12-31'
      }, {
        text: '长效期',
        value:'null'
      }],
      keyDownListener:null,
    };
  },
  created() {
      this.id=this.$route.query.id || '';
      if(this.id){
          this.get_data()
      }else{
          this.form.purchase_plan_date=this.today_val();
          this.form.purchase_acceptance_date=this.today_val();
          this.form.purchase_storage_date=this.today_val()
      }
      this.getOptions();
      this.remoteMethod()
  },
  mounted() {
  },
  computed: {
    newData() {
      return this.$store.state.isKeyDownListenerAdded
    },
  },
  watch: {
    newData(newval, oldval) {
      if (newval) {
        this.sele_products();
        this.$refs.kehu.blur();
      }
    }
  },
  methods: {
	  get_all(arr,k1,k2){
	  		  let n=0;
	  		  for(let i=0;i<arr.length;i++){
	  			  n+=(arr[i][k1] || 0)*(arr[i][k2] || 0)
	  		  }
	  		  return n.toFixed(4)
	  },
      get_price(id){
        let all_ids=[];
        for(let i=0;i<this.have_sele_arr.length;i++){
          if(all_ids.indexOf(this.have_sele_arr[i].id)==-1){
            all_ids.push(this.have_sele_arr[i].id)
          }
        }
        let ids=id || all_ids;
        if(this.form.merchant_id && ids.length>0){
          for(let i=0;i<ids.length;i++){
            this.get_item_price(ids[i])
          }
        }
      },
      get_item_price(id){
        this.$httpGet("/backend/PurchasePlan/purchasePlanPrice", {product_id:id,merchant_id:this.form.merchant_id}).then((res) => {
            if (res.status == 200) {
                for(let i=0;i<this.have_sele_arr.length;i++){
                  if(this.have_sele_arr[i].id==id){
                    this.have_sele_arr[i].product_price=res.data || undefined
                  }
                }
            } else {
                this.$message.error(res.message);
            }
        }).catch((err) => {
            console.log(err);
        });
      },
      num_change(index){
          this.have_sele_arr[index].acceptance_num=this.have_sele_arr[index].product_num
      },
      remoteMethod(keyword){
          this.$httpGet("/backend/Warehouse/index", {size:1000000,title:keyword}).then((res) => {
              if (res.status == 200) {
                  this.hwlist = res.data.data;
              } else {
                  this.$message.error(res.message);
              }
          }).catch((err) => {
              console.log(err);
          });
      },
    /** 删除按钮操作 */
    handleDelete(index) {
        const _this=this;
        this.$confirm('是否确认删除?', "警告", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning"
        }).then(function() {
            _this.have_sele_arr.splice(index,1)
        })
    },
    // 选择产品
    after_sele_Products(arr){
        let ids=[];
        for(let i=0;i<arr.length;i++){
            arr[i].acceptance_conclusion='验收合格';
            arr[i].quality_situation='质量合格';
            // arr[i].salesman_id=this.ywlist.length>0?this.ywlist[0].id:'';
            arr[i].warehouse_id=this.hwlist.length>0?this.hwlist[0].id:'';
            arr[i].salesman_id='';
            // arr[i].warehouse_id='';
            ids.push(arr[i].id)
        }
        this.get_price(ids)
        this.have_sele_arr=this.have_sele_arr.concat(arr)
        // 只能选一个
        // this.have_sele_arr=arr;
        // this.set_default_man()
    },
    // 默认设置第一个
    set_default_man(){
        // if((this.ywlist || []).length>0){
        //     for(let i=0;i<this.have_sele_arr.length;i++){
        //         this.have_sele_arr[i].salesman_id=this.ywlist[0].id
        //     }
        // }
    },
    sele_products(){
        if(!this.form.merchant_id){
          this.$message.error('请先选择供货单位');
          return false;
        }
        let ids=[]; // 已选择的id
        for(let i=0;i<this.have_sele_arr.length;i++){
            ids.push(this.have_sele_arr[i].id)
        }
        this.$refs['selectProducts'].init([],0)
    },
    
    /** 提交按钮 */
    submitForm: function(form) {
      this.$refs[form].validate((valid)  => {
        if (valid) {
          
          if(form=='form'){
            let _data={...this.form};
            
            let proarr=[];
            if(this.have_sele_arr.length==0){
                this.$message.error('请选择产品');
                return false;
            }
            for(let i=0;i<this.have_sele_arr.length;i++){
                let _item=this.have_sele_arr[i];
                if(!_item.product_num || (!_item.product_price && _item.product_price!=0) || !_item.acceptance_num || !_item.batch_number || !_item.acceptance_conclusion || !_item.quality_situation || !_item.warehouse_id){
                    this.$message.error('请完善产品信息');
                    return false;
                }else{
                    proarr.push({
                        product_id:_item.id,
                        product_num:_item.product_num,
                        product_price:_item.product_price,
                        amount:parseFloat((_item.product_price*_item.product_num).toFixed(3) || 0),
                        salesman_id:_item.salesman_id,
                        acceptance_num:_item.acceptance_num,
                        batch_number:_item.batch_number,
                        valid_until:_item.valid_until=='Invalid Date'?null:(_item.valid_until || null),
                        acceptance_conclusion:_item.acceptance_conclusion,
                        quality_situation:_item.quality_situation,
                        warehouse_id:_item.warehouse_id,
                    })
                }
            }
            
            
            let arr=[];
            for(let i=0;i<this.sh_arr.length;i++){
                if(!this.sh_arr[i].admin_id){
                    this.$message.error('请选择'+this.sh_arr[i].title+'经办人');
                    return false;
                }
            }
            
            
            // 提交
            this.is_ajax = true;
            _data.audit_array=JSON.stringify(this.sh_arr);
            _data.product_array=JSON.stringify(proarr);
            this.$httpPost("/backend/PurchaseStorage/onekeyStorage", _data).then((res) => {
                if (res.status == 200) {
                    this.$message.success(this.id ? '更新成功' : '新增成功');
                    // if(this.id){
                        window.is_need_back=true;
                        this.$router.go(-1)
                    // }else{
                    //     this.$nextTick(()=>{
                    //         this.reset();
                    //     })
                    // }
                } else {
                    this.$message.error(res.message);
                }
                this.is_ajax = false;
            }).catch((err) => {
                console.log(err);
                this.is_ajax = false;
            });
          }
        }
      });
    },
    get_data(){
        this.$httpGet("/backend/PurchasePlan/read", {id:this.id}).then((res) => {
            if (res.status == 200) {
                let _data=res.data;
                let proarr=[];
                for(let i=0;i<_data.purchase_plan_product.length;i++){
                    let _item=_data.purchase_plan_product[i];
                    let _pro=_item.product;
                    _pro.product_num=_item.product_num;
                    _pro.product_price=parseFloat(_item.product_price);
                    _pro.salesman_id=_item.salesman_id;
                    _pro.product_num=_item.product_num;
                    proarr.push(_pro)
                }
                this.have_sele_arr=proarr;
                
                for(let i=0;i<_data.purchase_plan_audit.length;i++){
                    this.sh_arr[i].admin_id=_data.purchase_plan_audit[i].admin_id;
                    this.sh_arr[i].opinion=_data.purchase_plan_audit[i].opinion.toString()
                }
                this.form=res.data
            } else {
                this.$message.error(res.message);
            }
        }).catch((err) => {
            console.log(err);
        });
    },
    getOptions(){
        this.$httpGet("/backend/SysConfig/index", {
            size: 10000000,
            groups: '采购计划'
        }).then((res) => {
            if (res.status == 200) {
                this.zjlist = res.data.data;
                if(this.zjlist.length>0){
                    this.form.plan_no=this.zjlist[0].value;
                }
            } else {
                this.$message.error(res.message);
            }
        }).catch((err) => {
            console.log(err);
        });
        
        this.$httpGet("/backend/SysConfig/index", {
            size: 10000000,
            groups: '采购验收'
        }).then((res) => {
            if (res.status == 200) {
                this.zjlist2 = res.data.data;
                if(this.zjlist2.length>0){
                    this.form.acceptance_no=this.zjlist2[0].value;
                }
            } else {
                this.$message.error(res.message);
            }
        }).catch((err) => {
            console.log(err);
        });
        this.$httpGet("/backend/SysConfig/index", {
            size: 10000000,
            groups: '采购入库'
        }).then((res) => {
            if (res.status == 200) {
                this.zjlist3 = res.data.data;
                if(this.zjlist3.length>0){
                    this.form.storage_no=this.zjlist3[0].value;
                }
            } else {
                this.$message.error(res.message);
            }
        }).catch((err) => {
            console.log(err);
        });
        
        
        this.$httpGet("/backend/sys.Admin/assistData", {}).then((res) => {
            if (res.status == 200) {
                this.list = res.data.department_list;
                if(!this.id){
                    for(let i=0;i<this.list.length;i++){
                        if(this.list[i].title=='采购部'){
                            this.form.department_id=this.list[i].id
                            break;
                        }
                    }
                }
            } else {
                this.$message.error(res.message);
            }
        }).catch((err) => {
            console.log(err);
        });
        
        
        // 获取经办人
        this.$httpGet("/backend/sys.Admin/handlerList", {size:10000000}).then((res) => {
            if (res.status == 200) {
                this.rylist = res.data;
                
                // 默认选中第一个
                if(!this.id && this.rylist.length>0){
                    for(let i=0;i<this.sh_arr.length;i++){
                        if(this.sh_arr[i].admin_id==''){
                            for(let k=0;k<this.rylist.length;k++){
                                if(this.rylist[k]['is_'+this.sh_arr[i].key]=='1'){
                                    this.sh_arr[i].admin_id=this.rylist[k].id;
                                    break;
                                }
                            }
                        }
                    }
                }
            } else {
                this.$message.error(res.message);
            }
        }).catch((err) => {
            console.log(err);
        });
        
        // 获取采购员 cglist
        this.$httpGet("/backend/sys.Admin/purchaseList", {size:10000000}).then((res) => {
            if (res.status == 200) {
                this.cglist = res.data.data || [];
                // if(!this.id && this.cglist.length>0){
                //     this.form.plan_admin_id=this.cglist[0].id
                // }
            } else {
                this.$message.error(res.message);
            }
        }).catch((err) => {
            console.log(err);
        });
        
        
        // 获取业务员 ywlist
        this.$httpGet("/backend/sys.Admin/salesmanList", {size:10000000}).then((res) => {
            if (res.status == 200) {
                this.ywlist = res.data.data;
            } else {
                this.$message.error(res.message);
            }
        }).catch((err) => {
            console.log(err);
        });
        
        // 获取验收员 yslist
        this.$httpGet("/backend/sys.Admin/acceptanceList", {size:10000000}).then((res) => {
            if (res.status == 200) {
                this.yslist = res.data.data;
                // if(!this.id && this.yslist.length>0){
                //     this.form.acceptance_admin_id=this.yslist[0].id
                // }
            } else {
                this.$message.error(res.message);
            }
        }).catch((err) => {
            console.log(err);
        });
        
        // 获取保管员 gblist
        this.$httpGet("/backend/sys.Admin/keeperList", {size:10000000}).then((res) => {
            if (res.status == 200) {
                this.bglist = res.data.data;
                // if(!this.id && this.bglist.length>0){
                //     this.form.storage_admin_id=this.bglist[0].id
                // }
            } else {
                this.$message.error(res.message);
            }
        }).catch((err) => {
            console.log(err);
        });
        
        
        this.$httpGet("/backend/Merchant/merchant_purchase", {size:10000000}).then((res) => {
            if (res.status == 200) {
                this.dwlist = res.data.data;
            } else {
                this.$message.error(res.message);
            }
        }).catch((err) => {
            console.log(err);
        });
    }
  },
  beforeDestroy() {  
    document.removeEventListener('keydown', this.keyDownListener);  
  }, 
};
</script>

<style lang="scss" scoped>
.table_Box {
  padding-left: 50px;
  font-size: 14px;
  .table_title {
    span {
      color: #1676e0;
      cursor: pointer;
    }
  }
  .el-table {
    margin: 30px 0;
  }
}
.top_box {
  display: flex;
  justify-content: space-around;
}
.examin {
  display: flex;
  justify-content: space-between;
}
</style>
